import * as Immutable from 'immutable';

import WidgetConfig from 'views/logic/widgets/WidgetConfig';
import type { After } from 'logview/types';

type InternalState = {
  after: After;
  fields: Immutable.OrderedSet<string>;
  size: number;
  sort: 'ASC' | 'DESC';
  tieBreaker: string;
  filtersOperator: 'AND' | 'OR';
  fieldFilters: Immutable.List<{
    field_name: string;
    value: string | Array<string>;
  }>;
};

export type DataLakeWidgetConfigJSON = {
  after: After;
  fields: Array<string>;
  size: InternalState['size'];
  sort: InternalState['sort'];
  tie_breaker: InternalState['tieBreaker'];
  filters_operator: 'AND' | 'OR';
  field_filters: Array<{
    field_name: string;
    value: string | Array<string>;
  }>;
};

export default class DataLakeWidgetConfig extends WidgetConfig {
  _value: InternalState;

  static defaultFields: InternalState['fields'] = Immutable.OrderedSet(['timestamp', 'source', 'message']);

  static defaultSortDirection: InternalState['sort'] = 'DESC';

  static defaultSize: InternalState['size'] = 100;

  static defaultFiltersOperator: InternalState['filtersOperator'] = 'AND';

  constructor(
    after: InternalState['after'],
    fields: InternalState['fields'],
    size: InternalState['size'],
    sort: InternalState['sort'],
    tieBreaker: InternalState['tieBreaker'],
    filtersOperator: InternalState['filtersOperator'],
    fieldFilters: InternalState['fieldFilters'],
  ) {
    super();

    this._value = { after, fields, size, sort, tieBreaker, filtersOperator, fieldFilters };
  }

  get after() {
    return this._value.after;
  }

  get fields() {
    return this._value.fields;
  }

  get filtersOperator() {
    return this._value.filtersOperator;
  }

  get fieldFilters() {
    return this._value.fieldFilters;
  }

  get size() {
    return this._value.size;
  }

  get sort() {
    return this._value.sort;
  }

  toBuilder() {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new Builder(Immutable.Map(this._value));
  }

  toJSON() {
    const {
      after,
      fields = Immutable.OrderedSet(),
      size,
      sort,
      tieBreaker,
      filtersOperator,
      fieldFilters,
    } = this._value;

    return {
      after,
      fields: fields.toArray(),
      size,
      sort,
      tie_breaker: tieBreaker,
      filters_operator: filtersOperator,
      field_filters: fieldFilters?.toArray() ?? [],
    };
  }

  static builder(): Builder {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new Builder().fields(Immutable.OrderedSet());
  }

  static fromJSON(value: DataLakeWidgetConfigJSON) {
    const { after, fields, size, sort, tie_breaker, filters_operator, field_filters } = value;

    return new DataLakeWidgetConfig(
      after,
      Immutable.OrderedSet(fields),
      size,
      sort,
      tie_breaker,
      filters_operator,
      Immutable.List(field_filters),
    );
  }

  static createDefault() {
    return new DataLakeWidgetConfig(
      undefined,
      DataLakeWidgetConfig.defaultFields,
      DataLakeWidgetConfig.defaultSize,
      DataLakeWidgetConfig.defaultSortDirection,
      undefined,
      DataLakeWidgetConfig.defaultFiltersOperator,
      undefined,
    );
  }
}

type BuilderState = Immutable.Map<string, any>;

class Builder {
  value: BuilderState;

  constructor(value: BuilderState = Immutable.Map()) {
    this.value = value;
  }

  after(value: InternalState['after']) {
    return new Builder(this.value.set('after', value));
  }

  fields(value: InternalState['fields']) {
    return new Builder(this.value.set('fields', value));
  }

  size(value: InternalState['size']) {
    return new Builder(this.value.set('size', value));
  }

  sort(value: InternalState['sort']) {
    return new Builder(this.value.set('sort', value));
  }

  tieBreaker(value: InternalState['tieBreaker']) {
    return new Builder(this.value.set('tieBreaker', value));
  }

  filtersOperator(value: InternalState['filtersOperator']) {
    return new Builder(this.value.set('filtersOperator', value));
  }

  fieldFilters(value: InternalState['fieldFilters']) {
    return new Builder(this.value.set('fieldFilters', value));
  }

  build() {
    const { after, fields, size, sort, tieBreaker, filtersOperator, fieldFilters } = this.value.toObject();

    return new DataLakeWidgetConfig(after, fields, size, sort, tieBreaker, filtersOperator, fieldFilters);
  }
}
