import __request__ from 'routing/request';
interface PaginatedResponse_IndicatorTemplate {
    readonly [_key: string]: Object;
}
interface IndicatorTemplateRequest {
    readonly indicator: string;
    readonly default: string[];
    readonly default_indicator: string;
    readonly gray: string[];
    readonly success: string[];
    readonly warning: string[];
    readonly description: string;
    readonly danger: string[];
    readonly type: string;
    readonly title: string;
    readonly info: string[];
    readonly primary: string[];
}
interface IndicatorTemplate {
    readonly indicator: string;
    readonly description: string;
    readonly danger: string[];
    readonly type: string;
    readonly title: string;
    readonly default: string[];
    readonly default_indicator: string;
    readonly gray: string[];
    readonly success: string[];
    readonly warning: string[];
    readonly id: string;
    readonly info: string[];
    readonly primary: string[];
}
interface IndicatorTemplateResponse {
    readonly indicator: string;
    readonly default: string[];
    readonly gray: string[];
    readonly success: string[];
    readonly warning: string[];
    readonly defaultIndicator: string;
    readonly danger: string[];
    readonly info: string[];
    readonly primary: string[];
}
interface IndicatorTemplateResponseMap {
    readonly [_key: string]: IndicatorTemplateResponse;
}
/**
 * Create a new Indicator Template
 * @param template template
 */
export function create(template?: IndicatorTemplateRequest): Promise<IndicatorTemplate> {
    return __request__('POST', '/plugins/org.graylog.plugins.contentui/indicator_template', template, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get all Indicator Templates
 */
export function getAll(page: number = 1, page_size: number = 50): Promise<PaginatedResponse_IndicatorTemplate> {
    return __request__('GET', '/plugins/org.graylog.plugins.contentui/indicator_template', null, { 'page': page, 'page_size': page_size }, {
        'Accept': ['application/json']
    });
}
/**
 * Get Indicator Template Mapping as required by the UI
 */
export function getIndicatorTemplateMapping(): Promise<IndicatorTemplateResponseMap> {
    return __request__('GET', '/plugins/org.graylog.plugins.contentui/indicator_template/ui_mapping', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get Indicator Template By ID
 * @param id Id
 */
export function getById(id?: string): Promise<IndicatorTemplate> {
    return __request__('GET', `/plugins/org.graylog.plugins.contentui/indicator_template/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update an existing Indicator Template
 * @param template template
 * @param id id
 */
export function update(template?: IndicatorTemplateRequest, id?: string): Promise<IndicatorTemplate> {
    return __request__('PUT', `/plugins/org.graylog.plugins.contentui/indicator_template/${id}`, template, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete an Indicator Template
 * @param id id
 */
export function remove(id?: string): Promise<void> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.contentui/indicator_template/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
