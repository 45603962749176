import * as React from 'react';

import { NoEntitiesExist } from 'components/common';
import type { MachineAssetAPIType } from 'security-app/hooks/api/assetsAPI.types';
import { DataTable } from 'security-app/components/common';
import { Col } from 'security-app/components/common/FormBaseStyles';

import { NoEntitiesExistWrapper } from '../shared';

type Props = {
  customFields: MachineAssetAPIType['custom_fields'];
};

function CustomFields({ customFields }: Props) {
  return Object.keys(customFields).length > 0 ? (
    <Col $gap="2rem" style={{ width: '100%' }}>
      {Object.keys(customFields).map((key: string) => (
        <DataTable condensed striped key={key}>
          <tbody>
            <DataTable.Row>
              <DataTable.Item $label>Field Name</DataTable.Item>
              <DataTable.Item>{key}</DataTable.Item>
            </DataTable.Row>
            <DataTable.Row>
              <DataTable.Item $label>Field Type</DataTable.Item>
              <DataTable.Item>{customFields[key].type}</DataTable.Item>
            </DataTable.Row>
            <DataTable.Row>
              <DataTable.Item $label>Field Value</DataTable.Item>
              <DataTable.Item>{customFields[key].values.join(', ')}</DataTable.Item>
            </DataTable.Row>
          </tbody>
        </DataTable>
      ))}
    </Col>
  ) : (
    <NoEntitiesExistWrapper>
      <NoEntitiesExist>No custom fields</NoEntitiesExist>
    </NoEntitiesExistWrapper>
  );
}

export default CustomFields;
