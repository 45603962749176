import * as React from 'react';
import { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { Alert, Button } from 'components/bootstrap';
import { RingProgress } from 'components/common';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';

const StyledAlert = styled(Alert)(
  ({ theme }) => css`
    padding: ${theme.spacings.xs};
    margin: 0;
    min-height: 45px;
  `,
);

const InnerContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${theme.spacings.sm};
  `,
);

const LeftCol = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacings.sm};
  `,
);

const StyledRingProgress = styled(RingProgress)`
  margin-bottom: -4px;
  margin-top: -4px;
`;

type Props = {
  onCancel?: () => Promise<void>;
  progress: number;
};

const ProgressInformation = ({ onCancel: onCancelProp = () => Promise.resolve(), progress }: Props) => {
  const [isCancelingExecution, setIsCancelingExecution] = useState(false);
  const sendTelemetry = useSendTelemetry();

  const [isLongRunningSearch, setIsLongRunningSearch] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      sendTelemetry(TELEMETRY_EVENT_TYPE.DATALAKE.PREVIEW.SEARCH_TAKES_LONGER, {
        app_pathname: '/data-storage/preview',
        app_section: 'loading-indicator',
      });
      setIsLongRunningSearch(true);
    }, 10000);

    return () => clearTimeout(timeoutId);
  }, [sendTelemetry]);

  const onCancel = () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.DATALAKE.PREVIEW.CANCEL_SEARCH, {
      app_pathname: '/data-storage/preview',
      app_section: 'loading-indicator',
    });

    setIsCancelingExecution(true);
    onCancelProp().finally(() => {
      setIsCancelingExecution(false);
    });
  };

  return (
    <StyledAlert bsStyle="info" noIcon>
      <InnerContainer>
        <LeftCol>
          <StyledRingProgress sections={[{ value: progress, color: 'info' }]} size={30} thickness={4} />
          <span>
            {!isLongRunningSearch
              ? 'We are retrieving your preview. This can take a moment.'
              : 'This is taking a bit longer, you can always leave this page and come back later when the results are ready.'}
          </span>
        </LeftCol>
        <Button bsStyle="default" bsSize="small" onClick={onCancel}>
          {isCancelingExecution ? 'Canceling...' : 'Cancel'}
        </Button>
      </InnerContainer>
    </StyledAlert>
  );
};

export default ProgressInformation;
