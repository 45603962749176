import React from 'react';
import type { FormikProps } from 'formik';
import { Field, Form, Formik } from 'formik';
import styled from 'styled-components';

import StreamFilter from 'data-lake/preview/StreamFilter';
import SearchBarFieldsFilter from 'data-lake/preview/SearchBarFieldsFilter';
import {
  SearchBarContainer,
  SearchButtonAndQuery,
  SearchInputAndValidationContainer,
  SearchQueryRow,
  TimeRangeRow,
} from 'views/components/searchbar/SearchBarLayout';
import TimeRangeFilter from 'views/components/searchbar/time-range-filter';
import type { TimeRange } from 'views/logic/queries/Query';
import SearchButton from 'views/components/searchbar/SearchButton';
import { Button } from 'components/bootstrap';
import type { FieldFilters, FieldsOperator } from 'data-lake/Types';
import { WIDGET_DEFAULTS } from 'data-lake/preview/Constants';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';

export const WAHREHOUSE_PREVIEW_FORM_ID = 'data-lake-preview-form';

export type FormValues = {
  timerange: TimeRange;
  stream: string | undefined;
  fields: { fieldFilters: Array<FieldFilters>; operator: FieldsOperator };
};

const StreamsContainer = styled.div`
  display: flex;
  flex: 1;
`;

const validate = (formValues: FormValues) => {
  const errors = {};

  if (!formValues.stream) {
    return { ...errors, stream: 'Stream is required' };
  }

  return errors;
};

type Props = {
  initialValues: FormValues;
  onReset: () => void;
  onSubmit: (formValues: FormValues) => void;
  formRef?: React.RefObject<FormikProps<FormValues>>;
  searchJobIsRunning: boolean;
};

const SearchBar = ({ searchJobIsRunning, onReset, initialValues, onSubmit, formRef = undefined }: Props) => {
  const sendTelemetry = useSendTelemetry();

  const onSubmitButtonClick = (e: MouseEvent) => {
    if (searchJobIsRunning) {
      // eslint-disable-next-line no-alert
      if (!window.confirm('Your previous search is currently running. Are you sure you want to start a new search?')) {
        sendTelemetry(TELEMETRY_EVENT_TYPE.DATALAKE.PREVIEW.START_NEW_SEARCH_WHILE_LOADING, {
          app_pathname: '/data-storage/preview',
          app_section: 'search-bar',
          app_action_value: 'cancel-search',
        });
        e.preventDefault();
      }
    }
  };

  const _onSubmit = (values: FormValues, { resetForm }) => {
    resetForm({ values: values });

    return onSubmit(values);
  };

  return (
    <Formik<FormValues> initialValues={initialValues} onSubmit={_onSubmit} validate={validate} innerRef={formRef}>
      {({ dirty, isSubmitting, isValid, isValidating, resetForm }) => {
        const disableSearchSubmit = isSubmitting || isValidating || !isValid;
        const resetSearch = () => {
          sendTelemetry(TELEMETRY_EVENT_TYPE.DATALAKE.PREVIEW.RESET_BUTTON_CLICKED, {
            app_pathname: '/data-storage/preview',
            app_section: 'search-bar',
            app_action_value: 'reset-button',
          });

          resetForm({
            values: {
              timerange: WIDGET_DEFAULTS.timerange,
              stream: undefined,
              fields: undefined,
            },
          });

          onReset();
        };

        return (
          <Form id={WAHREHOUSE_PREVIEW_FORM_ID}>
            <SearchBarContainer>
              <TimeRangeRow>
                <Field name="timerange">
                  {({ field: { name, value, onChange }, meta: { error } }) => (
                    <TimeRangeFilter
                      limitDuration={0}
                      onChange={(nextTimeRange) =>
                        onChange({
                          target: { value: nextTimeRange, name },
                        })
                      }
                      value={value}
                      hasErrorOnMount={!!error}
                      submitOnPresetChange={false}
                    />
                  )}
                </Field>
                <StreamsContainer>
                  <StreamFilter />
                </StreamsContainer>
              </TimeRangeRow>
              <SearchQueryRow>
                <SearchButtonAndQuery>
                  <SearchButton
                    disabled={disableSearchSubmit}
                    dirty={dirty}
                    displaySpinner={isSubmitting}
                    onClick={onSubmitButtonClick}
                  />
                  <SearchInputAndValidationContainer>
                    <SearchBarFieldsFilter />
                  </SearchInputAndValidationContainer>
                  <Button onClick={resetSearch} title="Clear filters and results">
                    Reset
                  </Button>
                </SearchButtonAndQuery>
              </SearchQueryRow>
            </SearchBarContainer>
          </Form>
        );
      }}
    </Formik>
  );
};
export default SearchBar;
