import { useMemo } from 'react';

import useInputs from 'hooks/useInputs';

const useInputOptions = () => {
  const inputs = useInputs();

  return useMemo(() => {
    if (!inputs) return [];

    return Object.values(inputs).map(({ id: value, title: label }) => ({ value, label }));
  }, [inputs]);
};

export default useInputOptions;
