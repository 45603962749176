import * as React from 'react';

import { NoEntitiesExist } from 'components/common';
import type { MachineAssetAPIType } from 'security-app/hooks/api/assetsAPI.types';
import { DataTable } from 'security-app/components/common';

import { NoEntitiesExistWrapper } from '../shared';

type Props = {
  geoInfo: MachineAssetAPIType['geo_info'];
};

function GeoInfo({ geoInfo }: Props) {
  return Object.values(geoInfo).some((value) => !!value) ? (
    <DataTable condensed striped>
      <tbody>
        <DataTable.Row>
          <DataTable.Item $label>Latitude</DataTable.Item>
          <DataTable.Item>{geoInfo.latitude}</DataTable.Item>
        </DataTable.Row>
        <DataTable.Row>
          <DataTable.Item $label>Longitude</DataTable.Item>
          <DataTable.Item>{geoInfo.longitude}</DataTable.Item>
        </DataTable.Row>
        <DataTable.Row>
          <DataTable.Item $label>Country ISO Code</DataTable.Item>
          <DataTable.Item>{geoInfo.country_iso_code}</DataTable.Item>
        </DataTable.Row>
        <DataTable.Row>
          <DataTable.Item $label>Country Name</DataTable.Item>
          <DataTable.Item>{geoInfo.country_name}</DataTable.Item>
        </DataTable.Row>
        <DataTable.Row>
          <DataTable.Item $label>Region</DataTable.Item>
          <DataTable.Item>{geoInfo.region}</DataTable.Item>
        </DataTable.Row>
        <DataTable.Row>
          <DataTable.Item $label>Time Zone</DataTable.Item>
          <DataTable.Item>{geoInfo.time_zone}</DataTable.Item>
        </DataTable.Row>
      </tbody>
    </DataTable>
  ) : (
    <NoEntitiesExistWrapper>
      <NoEntitiesExist>No geo information</NoEntitiesExist>
    </NoEntitiesExistWrapper>
  );
}

export default GeoInfo;
