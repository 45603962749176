import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { SecurityAppSecurityEventsHistogram } from '@graylog/enterprise-api';

import type { SearchParams } from 'stores/PaginationTypes';
import Spinner from 'components/common/Spinner';
import useMitreTactics from 'security-app/components/SecurityEvents/Alerts/MitreCategories/useMitreTactics';
import type { MiddleSectionProps } from 'components/common/PaginatedEntityTable/PaginatedEntityTable';
import useUserDateTime from 'hooks/useUserDateTime';
import { categoryKey } from 'security-app/components/SecurityEvents/Alerts/Constants';
import { parseSearchParams } from 'security-app/hooks/api/securityEventsAPI';
import useOnRefresh from 'components/common/PaginatedEntityTable/useOnRefresh';

import type { Bucket, GraphProps } from './Histogram';
import Histogram, { otherBucket } from './Histogram';

const fetchTacticsHistogram = async (
  searchParams: SearchParams,
  timezone: string,
): Promise<{ [tacticName: string]: Bucket }> => {
  const result = await SecurityAppSecurityEventsHistogram.tacticsHistogram(parseSearchParams(searchParams, timezone));

  return Object.fromEntries(result.tactics.map((tactic) => [tactic.id, tactic]));
};

type TacticsHistogram = { [tacticName: string]: Bucket };

const generateChart = (tactics: Array<[string, string]>, buckets: TacticsHistogram) => {
  const sortedTactics = [
    ...tactics.sort((t1, t2) => (buckets[t2[0]]?.count ?? 0) - (buckets[t1[0]]?.count ?? 0)),
    otherBucket,
  ];
  const x = sortedTactics.map((tactic) => tactic[0]);
  const text = sortedTactics.map((tactic) => tactic[1]);
  const y = sortedTactics.map((tactic) => buckets[tactic[0]]?.count ?? 0);

  return { x, y, text };
};

type TacticsGraphProps = {
  data: TacticsHistogram;
  tactics: Array<[string, string]>;
  onClickMarker: GraphProps['onClickMarker'];
};
const EventsTacticsGraph = ({ data, tactics, onClickMarker }: TacticsGraphProps) => {
  const { x, y, text } = useMemo(() => generateChart(tactics, data), [tactics, data]);

  return <Histogram onClickMarker={onClickMarker} x={x} y={y} text={text} type="Tactics" />;
};
const EventsTacticsHistogram = ({ searchParams, setFilters }: MiddleSectionProps) => {
  const { tactics, isLoadingTactics } = useMitreTactics();
  const { userTimezone } = useUserDateTime();
  const {
    data,
    isInitialLoading: isLoadingHistogram,
    refetch,
  } = useQuery(['events', 'tactics', searchParams, userTimezone], () =>
    fetchTacticsHistogram(searchParams, userTimezone),
  );
  useOnRefresh(refetch);
  const onClickMarker = useCallback(
    ({ x }: { x: string }) => {
      if (x !== otherBucket[0]) {
        setFilters(searchParams.filters.set(categoryKey, [x]));
      }
    },
    [searchParams.filters, setFilters],
  );

  return isLoadingTactics || isLoadingHistogram ? (
    <Spinner />
  ) : (
    <EventsTacticsGraph data={data} tactics={tactics} onClickMarker={onClickMarker} />
  );
};

export default EventsTacticsHistogram;
