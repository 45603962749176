import React, { useMemo, useState, useCallback, useRef, useEffect } from 'react';
import { styled, css } from 'styled-components';
import * as Immutable from 'immutable';
import type { FormikProps } from 'formik';
import { useQueryClient } from '@tanstack/react-query';

import { DataLakeQuery } from '@graylog/enterprise-api';

import type { FormValues } from 'data-lake/preview/SearchBar';
import SearchBar from 'data-lake/preview/SearchBar';
import SearchFeatureExplanation from 'data-lake/preview/SearchFeatureExplanation';
import DLLogViewWidget from 'data-lake/preview/DLLogViewWidget';
import DataLakeHighlightingRules from 'data-lake/preview/DataLakeHighlightingRules';
import { DocumentTitle, Spinner } from 'components/common';
import PageContentLayout from 'components/layout/PageContentLayout';
import useSearchConfiguration from 'hooks/useSearchConfiguration';
import Query from 'views/logic/queries/Query';
import { Alert, Row } from 'components/bootstrap';
import useFieldTypes from 'data-lake/hooks/useFieldTypes';
import DataLakeWidget from 'data-lake/logic/DataLakeWidget';
import Sidebar from 'views/components/sidebar/Sidebar';
import type HighlightingRule from 'views/logic/views/formatting/highlighting/HighlightingRule';
import HighlightingRulesContext from 'views/components/contexts/HighlightingRulesContext';
import FieldTypesContext from 'views/components/contexts/FieldTypesContext';
import useUserDateTime from 'hooks/useUserDateTime';
import Search from 'views/logic/search/Search';
import generateId from 'logic/generateId';
import useActiveSearch from 'data-lake/preview/hooks/useActiveSearch';
import {
  execute,
  executeSearchJob,
  resetState as resetSearchExecutionState,
} from 'views/logic/slices/searchExecutionSlice';
import DataLakePreviewStoreProvider from 'data-lake/preview/store/DataLakePreviewStoreProvider';
import createSearch from 'views/logic/slices/createSearch';
import { normalizeFromSearchBarForBackend } from 'views/logic/queries/NormalizeTimeRange';
import useIsLoading from 'views/hooks/useIsLoading';
import type { DataLakeSearchType, ActivePreviewSearch, MostRecentJobIds } from 'data-lake/preview/Types';
import {
  ICEBERG_QUERY,
  PAGE_SIZE,
  INITIAL_PAGE,
  MAX_LOADED_MESSAGES,
  PREVIEW_JOB_STATUS,
  HIDE_DL_SEARCH_EXPIRED_RESULT_ALERT,
  HIDE_DL_SEARCH_SUCCESSFUL_SEARCH_ALERT,
} from 'data-lake/preview/Constants';
import useUrlParams from 'data-lake/preview/hooks/useUrlParams';
import useInitialSearchBarFormValues from 'data-lake/preview/hooks/useInitialFormValues';
import useWidgetResults from 'data-lake/preview/hooks/useWidgetResults';
import type DataLakeWidgetConfig from 'data-lake/logic/DataLakeWidgetConfig';
import ProgressInformation from 'data-lake/preview/ProgressInformation';
import LogViewSkeleton from 'data-lake/preview/LogViewSkeleton';
import Delayed from 'components/common/Delayed';
import type FieldTypeMapping from 'views/logic/fieldtypes/FieldTypeMapping';
import useCurrentUser from 'hooks/useCurrentUser';
import { isPermitted } from 'util/PermissionsMixin';
import Panel from 'components/bootstrap/Panel';
import { Link } from 'components/common/router';
import DataLakeRoutes from 'data-lake/Routes';
import PreviewIntro from 'data-lake/preview/PreviewIntro';
import ExpiredSearchInfo from 'data-lake/preview/ExpiredSearchInfo';
import { selectJobIds } from 'views/logic/slices/searchExecutionSelectors';
import ExecutionInfo from 'views/components/views/ExecutionInfo';
import useJobProgress from 'data-lake/preview/hooks/useJobProgress';
import formatBackendMessages from 'data-lake/preview/utils/formatBackendMessages';
import type { ExtraArguments } from 'views/types';
import useHasActiveBackend from 'data-lake/preview/hooks/useHasActiveBackend';
import useLicenseValidityForSubject from 'license/hooks/useLicenseValidityForSubject';
import { LICENSE_SUBJECTS } from 'license/constants';
import { InvalidLicenseWarning } from 'license/LicenseCheck';
import type { DLPreviewDispatch } from 'data-lake/preview/store/useDLPreviewDispatch';
import useDLPreviewDispatch from 'data-lake/preview/store/useDLPreviewDispatch';
import useDLPreviewSelector from 'data-lake/preview/store/useDLPreviewSelector';
import Store from 'logic/local-storage/Store';
import stopSearchResultsPolling from 'data-lake/preview/utils/stopSearchResultsPolling';
import { cancelJob } from 'views/logic/slices/executeJobResult';
import SuccessfulSearchInfo from 'data-lake/preview/SuccessfulSearchInfo';

import createInitialWidget, { defaultWidget } from './utils/createInitialWidget';

const StyledPageContentLayout = styled(PageContentLayout)`
  .page-content-grid {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: auto;
  }
`;

const GridContainer = styled.div`
  display: flex;
  height: 100%;
`;

const FullHeightRow = styled(Row)`
  flex: 1;
  overflow: auto;
`;

const StyledRow = styled(Row)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacings.xs};
  `,
);

const StyledAlert = styled(Alert)`
  margin: 0;
`;

const Header = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: ${theme.spacings.xs};
    gap: ${theme.spacings.sm};
    justify-content: space-between;
  `,
);

const reexecuteSearchJob =
  (searchJobId: string, executingNodeId: string) =>
  (dispatch: DLPreviewDispatch, _getState, { searchExecutors }: ExtraArguments) =>
    dispatch(
      executeSearchJob({
        stopPolling: (progress: number) => stopSearchResultsPolling(INITIAL_PAGE, progress),
        searchExecutors,
        jobIds: {
          asyncSearchId: searchJobId,
          nodeId: executingNodeId,
        },
        page: INITIAL_PAGE,
        perPage: PAGE_SIZE,
      }),
    );

const usePollResultsForInitialSearchJob = (
  searchJobStatus: ActivePreviewSearch['status'] | undefined,
  searchJobProgress: number | undefined,
  searchJobId: string | undefined,
  executingNodeId: string | undefined,
) => {
  const dispatch = useDLPreviewDispatch();

  useEffect(() => {
    if (searchJobStatus === PREVIEW_JOB_STATUS.running && !stopSearchResultsPolling(INITIAL_PAGE, searchJobProgress)) {
      dispatch(reexecuteSearchJob(searchJobId, executingNodeId));
    }
  }, [dispatch, executingNodeId, searchJobId, searchJobProgress, searchJobStatus]);
};

const useMostRecentJobIds = (
  initialSearchJob: {
    status: ActivePreviewSearch['status'];
    id: string;
    nodeId: string;
  },
  initialSearchId: string | undefined,
  executedSearchId: string | undefined,
) => {
  const jobIds = useDLPreviewSelector(selectJobIds);

  const initialSearchJobId =
    initialSearchJob.status === PREVIEW_JOB_STATUS.running || initialSearchJob.status === PREVIEW_JOB_STATUS.done
      ? {
          asyncSearchId: initialSearchJob.id,
          nodeId: initialSearchJob.nodeId,
          searchId: initialSearchId,
        }
      : undefined;

  const [mostRecentJobIds, setMostRecentJobIds] = useState<MostRecentJobIds | undefined>(initialSearchJobId);

  useEffect(() => {
    if (
      jobIds &&
      (mostRecentJobIds?.asyncSearchId !== jobIds.asyncSearchId || mostRecentJobIds?.nodeId !== jobIds.nodeId)
    ) {
      setMostRecentJobIds({ ...jobIds, searchId: executedSearchId });
    }
  }, [executedSearchId, jobIds, mostRecentJobIds]);

  return mostRecentJobIds;
};

type Props = {
  initialSearch: Search | undefined;
  initialUrlParams: FormValues;
  resetUrlQueryParams: () => void;
  initialSearchJob: { status: ActivePreviewSearch['status']; id: string; nodeId: string; progress: number } | undefined;
};

const resetAlertFlags = () => {
  Store.set(HIDE_DL_SEARCH_EXPIRED_RESULT_ALERT, 'false');
  Store.set(HIDE_DL_SEARCH_SUCCESSFUL_SEARCH_ALERT, 'false');
};
const DataLakePreview = ({ initialSearch, initialUrlParams, resetUrlQueryParams, initialSearchJob }: Props) => {
  const dispatch = useDLPreviewDispatch();
  const { userTimezone } = useUserDateTime();
  const searchBarFormRef = useRef<FormikProps<FormValues>>(null);
  const [executedSearch, setExecutedSearch] = useState<Search | undefined>(
    initialSearchJob.status === PREVIEW_JOB_STATUS.done ||
      initialSearchJob.status === PREVIEW_JOB_STATUS.running ||
      initialSearchJob.status === PREVIEW_JOB_STATUS.error
      ? initialSearch
      : undefined,
  );
  const { widgetData, errors, executedAt, duration } = useWidgetResults(executedSearch);
  const mostRecentJobIds = useMostRecentJobIds(initialSearchJob, initialSearch?.id, executedSearch?.id);
  const queryClient = useQueryClient();
  const [widget, setWidget] = useState<DataLakeWidget>(createInitialWidget(initialSearch));
  const [highlightingRules, setHighlightingRules] = useState<Array<HighlightingRule>>([]);
  const [editing, setEditing] = useState(false);
  const initialFormValues = useInitialSearchBarFormValues(executedSearch, initialUrlParams);
  const { data: jobProgress, setEnableProgressFetching } = useJobProgress(
    mostRecentJobIds,
    initialSearchJob.status,
    executedSearch?.id,
  );
  const isExecutionFirstJob = useIsLoading();
  const searchJobIsRunning = isExecutionFirstJob || jobProgress?.status === PREVIEW_JOB_STATUS.running;

  usePollResultsForInitialSearchJob(
    initialSearchJob.status,
    initialSearchJob.progress,
    initialSearchJob.id,
    initialSearchJob.nodeId,
  );

  const onChangeWidget = useCallback((newWidget: DataLakeWidget) => {
    setWidget(newWidget);

    return Promise.resolve();
  }, []);

  const toggleEdit = useCallback(() => setEditing((cur) => !cur), []);

  const onReset = useCallback(() => {
    setExecutedSearch(undefined);
    setWidget(defaultWidget);
    setEditing(false);
    resetUrlQueryParams();

    return DataLakeQuery.resetActiveQuery().then(() => {
      queryClient.invalidateQueries(['data-lake']);

      return dispatch(resetSearchExecutionState());
    });
  }, [dispatch, queryClient, resetUrlQueryParams]);

  const highlightSidebarSection = useCallback(
    () => <DataLakeHighlightingRules setHighlightingRules={setHighlightingRules} />,
    [],
  );

  const onSearchBarSubmit = useCallback(
    async ({ timerange, stream, fields }: FormValues) => {
      resetUrlQueryParams();
      resetAlertFlags();
      const updatedWidgetConfig: DataLakeWidgetConfig = widget.config
        .toBuilder()
        .fieldFilters(fields?.fieldFilters ? Immutable.List(fields.fieldFilters) : widget.config.fieldFilters)
        .filtersOperator(fields?.operator ?? widget.config.filtersOperator)
        .build();

      const updatedWidget = widget
        .toBuilder()
        .config(updatedWidgetConfig)
        .timerange(timerange ? normalizeFromSearchBarForBackend(timerange, userTimezone) : widget.timerange)
        .streams([stream])
        .build();

      const query = Query.builder()
        .id(generateId())
        .query(ICEBERG_QUERY)
        .searchTypes([
          {
            size: MAX_LOADED_MESSAGES,
            filters_operator: updatedWidget.config.filtersOperator,
            timerange: updatedWidget.timerange,
            streams: [stream],
            field_filters: updatedWidget.config.fieldFilters.toArray(),
            fields: updatedWidget.config.fields.toArray(),
            type: DataLakeWidget.type,
            filters: [],
            sort: updatedWidget.config.sort,
          } as unknown as DataLakeSearchType,
        ])
        .build();

      const search = await createSearch(Search.create().toBuilder().queries([query]).build());

      dispatch(resetSearchExecutionState());

      setWidget(updatedWidget);
      setEditing(false);
      setExecutedSearch(search);
      setEnableProgressFetching(true);

      return dispatch(
        execute({
          search,
          activeQuery: search.queries.first().id,
          page: INITIAL_PAGE,
          perPage: PAGE_SIZE,
          stopPolling: (progress) => stopSearchResultsPolling(INITIAL_PAGE, progress),
        }),
      );
    },
    [dispatch, resetUrlQueryParams, setEnableProgressFetching, userTimezone, widget],
  );

  const messages = useMemo(() => formatBackendMessages(widgetData?.messages), [widgetData?.messages]);
  const hasResult = messages?.length > 0;

  return (
    <HighlightingRulesContext.Provider value={highlightingRules}>
      <GridContainer>
        <Sidebar
          sections={[
            {
              key: 'highlighting',
              icon: 'format_paragraph' as const,
              title: 'Highlighting',
              content: highlightSidebarSection,
            },
          ]}
          enableSidebarPinning={false}
          actions={[]}
          title="Data Lake Preview"
        />
        <StyledPageContentLayout>
          <SearchFeatureExplanation />
          {initialSearchJob.status === 'EXPIRED' && initialSearch && !executedSearch && !searchJobIsRunning && (
            <ExpiredSearchInfo expiredSearch={initialSearch} searchBarFormRef={searchBarFormRef} />
          )}
          <Row>
            <Header>
              <div>Data Lake Preview</div>
              <ExecutionInfo
                duration={duration}
                executedAt={executedAt}
                executionFinished={executedSearch && !searchJobIsRunning}
                showTotal={false}
              />
            </Header>
          </Row>
          <SearchBar
            searchJobIsRunning={searchJobIsRunning}
            onReset={onReset}
            initialValues={initialFormValues}
            onSubmit={onSearchBarSubmit}
            formRef={searchBarFormRef}
          />
          {!executedSearch && !searchJobIsRunning && (
            <FullHeightRow>
              <PreviewIntro />
            </FullHeightRow>
          )}
          {executedSearch && (
            <>
              {jobProgress?.status === PREVIEW_JOB_STATUS.running && (
                <Delayed delay={initialSearchJob.status === PREVIEW_JOB_STATUS.running ? 0 : 300}>
                  <StyledRow>
                    <ProgressInformation
                      onCancel={() => {
                        setExecutedSearch(undefined);
                        if (mostRecentJobIds) {
                          return cancelJob(mostRecentJobIds);
                        }

                        return Promise.resolve();
                      }}
                      progress={jobProgress.progress}
                    />
                  </StyledRow>
                  {searchJobIsRunning && !hasResult && (
                    <FullHeightRow>
                      <LogViewSkeleton columns={widget.config.fields} widgetId={widget.id} />
                    </FullHeightRow>
                  )}
                </Delayed>
              )}

              {errors.length > 0 && (
                <StyledRow>
                  <StyledAlert bsStyle="danger">{errors.map((error) => error.description)}</StyledAlert>
                </StyledRow>
              )}

              {errors.length === 0 && (
                <>
                  {jobProgress?.status === PREVIEW_JOB_STATUS.done && <SuccessfulSearchInfo executedAt={executedAt} />}
                </>
              )}

              {hasResult && (
                <FullHeightRow>
                  <DLLogViewWidget
                    initialPageTotal={widgetData.total}
                    mostRecentJobIds={mostRecentJobIds}
                    effectiveTimerange={widgetData.effectiveTimerange}
                    messages={messages}
                    isFetching={false}
                    onToggleEdit={toggleEdit}
                    onChangeWidget={onChangeWidget}
                    widget={widget}
                    editing={editing}
                  />
                </FullHeightRow>
              )}

              {!searchJobIsRunning && !hasResult && (
                <StyledRow>
                  <StyledAlert>No messages have been found for selected filters.</StyledAlert>
                </StyledRow>
              )}
            </>
          )}
        </StyledPageContentLayout>
      </GridContainer>
    </HighlightingRulesContext.Provider>
  );
};

const DataLakePreviewPageComponent = () => {
  const { initialUrlParams, resetUrlQueryParams } = useUrlParams();
  const { config } = useSearchConfiguration();
  const { isInitialLoading: isLoadingFields, data: fieldTypes } = useFieldTypes();
  const {
    search: activeSearch,
    result: activeSearchResult,
    searchJob,
    isLoading: isLoadingActiveSearch,
  } = useActiveSearch(initialUrlParams);
  const fieldTypesContextValue = useMemo(
    () => ({
      all: Immutable.List(fieldTypes) ?? Immutable.List(),
      currentQuery: Immutable.List<FieldTypeMapping>(),
    }),
    [fieldTypes],
  );

  return (
    <DocumentTitle title="Data Lake Preview">
      {config && !isLoadingFields && !isLoadingActiveSearch ? (
        <DataLakePreviewStoreProvider result={activeSearchResult}>
          <FieldTypesContext.Provider value={fieldTypesContextValue}>
            <DataLakePreview
              initialSearch={activeSearch}
              initialUrlParams={initialUrlParams}
              initialSearchJob={searchJob}
              resetUrlQueryParams={resetUrlQueryParams}
            />
          </FieldTypesContext.Provider>
        </DataLakePreviewStoreProvider>
      ) : (
        <Spinner />
      )}
    </DocumentTitle>
  );
};

const DataLakePreviewPagePlug = () => {
  const currentUser = useCurrentUser();
  const hasConfigPermissions = isPermitted(currentUser.permissions, 'data_lake_config:update');

  return (
    <PageContentLayout>
      <Panel bsStyle="info">
        <Panel.Heading>There is no active Data Lake Backend</Panel.Heading>
        <Panel.Body>
          To be able open the <b>Data Lake Preview Page</b> you need to configure and activate a{' '}
          {hasConfigPermissions ? (
            <Link to={DataLakeRoutes.unqualified.BACKEND}>Data Lake Backend</Link>
          ) : (
            <>
              Data Lake Backend. <span>To set up a Data Lake contact your Administrator.</span>
            </>
          )}
        </Panel.Body>
      </Panel>
    </PageContentLayout>
  );
};
const DataLakePreviewPage = () => {
  const {
    data: { valid: isValidLicense },
    isInitialLoading: isLoadingLicense,
  } = useLicenseValidityForSubject(LICENSE_SUBJECTS.enterprise);
  const { data: hasActiveBackend, isInitialLoading: isLoadingConfig } = useHasActiveBackend();

  if (isLoadingConfig || isLoadingLicense) {
    return <Spinner />;
  }

  if (!isValidLicense) {
    return (
      <PageContentLayout>
        <InvalidLicenseWarning
          title="Data Lake Preview is disabled"
          featureName="Data Lake Preview"
          licenseSubject={LICENSE_SUBJECTS.enterprise}
          displayWarningContainer
          bsStyle="danger"
        />
      </PageContentLayout>
    );
  }

  if (!hasActiveBackend) {
    return <DataLakePreviewPagePlug />;
  }

  return <DataLakePreviewPageComponent />;
};
export default DataLakePreviewPage;
