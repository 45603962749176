import __request__ from 'routing/request';
interface Availability {
    readonly available: boolean;
}
type ArchiveRestoreJobSummaryArray = ArchiveRestoreJobSummary[];
interface SegmentDTO {
    readonly path: string;
    readonly checksum_type: string;
    readonly size: number;
    readonly compression_type: string;
    readonly checksum: string;
    readonly raw_size: number;
}
interface IndexArchiveMetadata {
    readonly index_shard_count: number;
    readonly source_histogram: {
        readonly [_key: string]: {
            readonly [_key: string]: number;
        };
    };
    readonly document_count: number;
    readonly histogram_bucket_size: number;
    readonly timestamp_min: string;
    readonly index_size: number;
    readonly index_settings: string;
    readonly created_at: string;
    readonly stream_histogram: {
        readonly [_key: string]: {
            readonly [_key: string]: number;
        };
    };
    readonly creation_duration: string;
    readonly segments: IndexArchiveSegment[];
    readonly id_mappings: IdMappings;
    readonly index_mapping: string;
    readonly timestamp_max: string;
    readonly archive_id: string;
    readonly index_name: string;
}
interface IndexArchiveContext {
    readonly path: string;
    readonly backend_id: string;
    readonly restored_index_name: string;
    readonly restored: boolean;
    readonly archive_id: string;
    readonly index_name: string;
}
interface ArchiveIndexJobSummary {
    readonly archive_job_config: ArchiveConfig;
    readonly system_job: SystemJobSummary;
}
interface ArchiveRestoreJobSummary {
    readonly archive_metadata: IndexArchiveMetadata;
    readonly system_job: SystemJobSummary;
}
interface SystemJobSummary {
    readonly job_status: 'runnable' | 'running' | 'complete' | 'paused' | 'error' | 'cancelled';
    readonly provides_progress: boolean;
    readonly execution_duration: string;
    readonly name: string;
    readonly is_cancelable: boolean;
    readonly description: string;
    readonly started_at: string;
    readonly id: string;
    readonly percent_complete: number;
    readonly info: string;
    readonly node_id: string;
}
interface IdMappingsDTO {
    readonly nodes: {
        readonly [_key: string]: string;
    };
    readonly inputs: {
        readonly [_key: string]: string;
    };
    readonly streams: {
        readonly [_key: string]: string;
    };
}
interface IndexArchiveSegment {
    readonly path: string;
    readonly checksum_type: 'NONE' | 'CRC32' | 'MD5' | 'SHA1' | 'SHA256';
    readonly size: number;
    readonly compression_type: 'NONE' | 'GZIP' | 'SNAPPY' | 'LZ4' | 'ZSTD';
    readonly checksum: string;
    readonly raw_size: number;
}
interface ArchiveRestoreBulkRequest {
    readonly archives: {
        readonly [_key: string]: string[];
    };
}
interface Response {
    readonly type: string;
}
interface ArchiveBackendContext {
    readonly description: string;
    readonly id: string;
    readonly title: string;
}
interface CatalogEntryDTO {
    readonly index_shard_count: number;
    readonly source_histogram: {
        readonly [_key: string]: {
            readonly [_key: string]: number;
        };
    };
    readonly document_count: number;
    readonly histogram_bucket_size: number;
    readonly timestamp_min: string;
    readonly source_names: string[];
    readonly index_size: number;
    readonly created_at: string;
    readonly stream_histogram: {
        readonly [_key: string]: {
            readonly [_key: string]: number;
        };
    };
    readonly creation_duration: string;
    readonly segments: SegmentDTO[];
    readonly id_mappings: IdMappingsDTO;
    readonly timestamp_max: string;
    readonly backend_id: string;
    readonly node_names: string[];
    readonly stream_names: string[];
    readonly id: string;
    readonly archive_id: string;
    readonly index_name: string;
}
interface CatalogPage {
    readonly per_page: number;
    readonly total: number;
    readonly archives: CatalogEntryDTO[];
    readonly query: string;
    readonly count: number;
    readonly page: number;
    readonly backends_context: {
        readonly [_key: string]: ArchiveBackendContext;
    };
    readonly archives_context: {
        readonly [_key: string]: {
            readonly [_key: string]: IndexArchiveContext;
        };
    };
}
interface IndexArchivesSummary {
    readonly archives: IndexArchiveMetadata[];
    readonly archives_context: {
        readonly [_key: string]: IndexArchiveContext;
    };
}
interface ArchiveConfig {
    readonly segment_checksum_type: 'NONE' | 'CRC32' | 'MD5' | 'SHA1' | 'SHA256';
    readonly histogram_bucket_size: number;
    readonly segment_compression_type: 'NONE' | 'GZIP' | 'SNAPPY' | 'LZ4' | 'ZSTD';
    readonly parallelize_archive_creation: boolean;
    readonly restore_index_batch_size: number;
    readonly segment_filename_prefix: string;
    readonly archive_failure_threshold: number;
    readonly restrict_to_leader: boolean;
    readonly excluded_streams: string[];
    readonly metadata_filename: string;
    readonly backend_id: string;
    readonly retention_time: number;
    readonly max_segment_size: number;
    readonly archive_path: string;
}
interface ArchiveRebuildJobSummary {
    readonly system_job: SystemJobSummary;
}
interface IdMappings {
    readonly nodes: {
        readonly [_key: string]: string;
    };
    readonly inputs: {
        readonly [_key: string]: string;
    };
    readonly streams: {
        readonly [_key: string]: string;
    };
}
/**
 * Returns all existing archives
 */
export function all(): Promise<IndexArchivesSummary> {
    return __request__('GET', '/plugins/org.graylog.plugins.archive/archives', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Returns all existing archives for the given backend
 */
export function allForBackend(backendId?: string): Promise<IndexArchivesSummary> {
    return __request__('GET', `/plugins/org.graylog.plugins.archive/archives/backend/${backendId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete the archive for the given backend and archive id
 */
export function deleteByBackend(backendId: string, archiveId: string): Promise<IndexArchiveMetadata> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.archive/archives/backend/${backendId}/${archiveId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Restore the given archive
 */
export function restoreFromBackend(backendId: string, archiveId: string): Promise<ArchiveRestoreJobSummary> {
    return __request__('POST', `/plugins/org.graylog.plugins.archive/archives/backend/${backendId}/${archiveId}/restore`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Restore the given archives
 */
export function bulkRestoreFromBackend(archives: ArchiveRestoreBulkRequest): Promise<ArchiveRestoreJobSummaryArray> {
    return __request__('POST', '/plugins/org.graylog.plugins.archive/archives/bulkrestore', archives, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Returns the matching archives from the catalog
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function catalog(sort: 'created_at' | ' index_name' | ' document_count' = 'created_at', page: number = 1, per_page: number = 50, order: 'asc' | 'desc' = 'desc', query?: string): Promise<CatalogPage> {
    return __request__('GET', '/plugins/org.graylog.plugins.archive/archives/catalog', null, { 'page': page, 'per_page': per_page, 'sort': sort, 'order': order, 'query': query }, {
        'Accept': ['application/json']
    });
}
/**
 * Exports a list of certain archive attributes
 * @param sort The field to sort the result on
 * @param exportType The attribute to be exported
 */
export function exportFilenames(sort: 'created_at' | ' index_name' | ' document_count' = 'created_at', exportType: 'filename' = 'filename', query?: string, limit: number = 10000): Promise<Response> {
    return __request__('GET', `/plugins/org.graylog.plugins.archive/archives/catalog/export/${exportType}`, null, { 'query': query, 'limit': limit, 'sort': sort }, {
        'Accept': ['application/json']
    });
}
/**
 * Rebuild the archive catalog from disk
 */
export function rebuild(): Promise<ArchiveRebuildJobSummary> {
    return __request__('POST', '/plugins/org.graylog.plugins.archive/archives/catalog/rebuild', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Check the on-disk availability of the given catalog entry
 */
export function checkAvailability(entryId: string): Promise<Availability> {
    return __request__('GET', `/plugins/org.graylog.plugins.archive/archives/catalog/${entryId}/available`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete the archive for the given id
 */
export function remove(archiveId: string): Promise<IndexArchiveMetadata> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.archive/archives/${archiveId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Restore the given archive
 */
export function restore(archiveId: string): Promise<ArchiveRestoreJobSummary> {
    return __request__('POST', `/plugins/org.graylog.plugins.archive/archives/${archiveId}/restore`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Archive the given index
 */
export function run(indexName: string, index_action: 'NONE' | 'CLOSE' | 'DELETE' = 'NONE'): Promise<ArchiveIndexJobSummary> {
    return __request__('POST', `/plugins/org.graylog.plugins.archive/archives/${indexName}`, null, { 'index_action': index_action }, {
        'Accept': ['application/json']
    });
}
