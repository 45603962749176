import * as React from 'react';
import { useMemo } from 'react';
import { styled } from 'styled-components';

import type { ColumnRenderers } from 'components/common/EntityDataTable';
import type { SecurityEventEntity } from 'security-app/hooks/api/securityEventsAPI.types';
import { Badge } from 'common/components';
import { getGeneralEventAttributeRenderers } from 'components/events/events/ColumnRenderers';
import { RiskScoreBadge } from 'security-app/components/common';
import useUsersAndTeams from 'security-app/components/Investigations/hooks/useUsersAndTeams';
import { SECURITY_EVENT_STATUSES } from 'security-app/hooks/api/securityEventsAPI.types';
import { useGetEventDefinitionDetailsBatched, useGetSecurityEvent } from 'security-app/hooks/useSecurityEventsAPI';
import EventDefinitionLink from 'components/events/events/EventDefinitionLink';
import Routes from 'security-app/routing/Routes';
import { Link } from 'components/common/router';
import useMitreTactics from 'security-app/components/SecurityEvents/Alerts/MitreCategories/useMitreTactics';
import Spinner from 'components/common/Spinner';
import useMitreTechniques from 'security-app/components/SecurityEvents/Alerts/MitreCategories/useMitreTechniques';
import { categoryKey } from 'security-app/components/SecurityEvents/Alerts/Constants';
import Label from 'components/bootstrap/Label';

import AssigneeCell from '../../common/AssigneeCell';

const AssociatedInvestigations = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

export const OwnerCell = ({ owner }: { owner: string }) => {
  const usersAndTeams = useUsersAndTeams();

  return <>{usersAndTeams[owner] ? <AssigneeCell assignee={usersAndTeams[owner]} /> : <i>none</i>}</>;
};

export const CategoryCell = ({ category, withTitle = false }: { category: string; withTitle?: boolean }) => {
  const { tactics, isLoadingTactics } = useMitreTactics();
  const { techniques, isLoadingTechniques } = useMitreTechniques([]);
  const title = useMemo(() => {
    if (isLoadingTactics || isLoadingTechniques) {
      return undefined;
    }
    const _category = category.toLocaleUpperCase();

    const tacticName = (_category.startsWith('TA') ? tactics : techniques).find(
      ([id]) => id.toLocaleUpperCase() === _category,
    )?.[1];

    return `${tacticName} (${category})`;
  }, [category, isLoadingTactics, isLoadingTechniques, tactics, techniques]);

  return isLoadingTactics || isLoadingTechniques ? (
    <Spinner />
  ) : (
    <span title={withTitle ? title : undefined}>{title}</span>
  );
};

const EventDefinitionRenderer = ({ eventDefinitionId }: { eventDefinitionId: string }) => {
  const { eventDefinition, loadingEventDefinition } = useGetEventDefinitionDetailsBatched(eventDefinitionId);
  const title = eventDefinition?.title;

  return !loadingEventDefinition && <EventDefinitionLink id={eventDefinitionId} title={title} />;
};

const AssociatedInvestigationsRenderer = ({ eventId }: { eventId: string }) => {
  const { securityEvent, loadingSecurityEvents } = useGetSecurityEvent(eventId);

  if (loadingSecurityEvents) return null;

  return (
    <AssociatedInvestigations>
      {securityEvent?.associated_investigations?.map((investigation) => (
        <Link role="link" to={Routes.INVESTIGATIONS.details(investigation.id)}>
          {investigation.name}
        </Link>
      ))}
    </AssociatedInvestigations>
  );
};

const stripPrefix = (category: string) => category.replace(/^attack\./, '');

const isTechnique = (category: string) => /^t\d+/.test(category.toLocaleLowerCase());

const CategoryLabel = styled(Label)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  max-width: fit-content;
`;
const TacticTechnique = ({ value }: { value: string }) => {
  const _value = useMemo(() => stripPrefix(value), [value]);

  return (
    <CategoryLabel bsStyle="default">
      <CategoryCell withTitle category={_value} />
    </CategoryLabel>
  );
};

const CategoriesContainer = styled.div`
  overflow: hidden;
  display: flex;
  gap: 5px;
  flex-direction: column;
`;

const MitreCategoryRenderer = ({ entity }: { entity: SecurityEventEntity }) => (
  <CategoriesContainer>
    {Object.entries(entity.fields)
      .filter(([key]) => key.startsWith('sigma_rule_tag_'))
      .filter(([_key, value]) => isTechnique(stripPrefix(value)))
      .map(([_key, value]) => (
        <TacticTechnique key={`cell-${entity.id}-category-${value}`} value={value} />
      ))}
  </CategoriesContainer>
);

const customColumnRenderers: ColumnRenderers<SecurityEventEntity> = {
  attributes: {
    ...getGeneralEventAttributeRenderers<SecurityEventEntity>(),
    scores: {
      staticWidth: 100,
      renderCell: (scores: { [key: string]: number }) => <RiskScoreBadge riskScore={scores?.normalized_risk || 0} />,
    },
    owner: {
      renderCell: (owner: string) => <OwnerCell owner={owner} />,
      staticWidth: 200,
    },
    status: {
      renderCell: (status: string) => <span>{SECURITY_EVENT_STATUSES[status]}</span>,
      staticWidth: 120,
    },
    event_definition_id: {
      width: 0.2,
      renderCell: (eventDefinitionId: string) => <EventDefinitionRenderer eventDefinitionId={eventDefinitionId} />,
    },
    associated_investigations: {
      renderCell: (_, { event_id }) => <AssociatedInvestigationsRenderer eventId={event_id} />,
      width: 0.2,
      minWidth: 200,
    },
    part_of_detection_chain: {
      renderCell: (part_of_detection_chain: boolean) =>
        part_of_detection_chain ? <Badge variant="warning">detection chain</Badge> : null,
      staticWidth: 150,
    },
    [categoryKey]: {
      renderCell: (_field, entity) => <MitreCategoryRenderer entity={entity} />,
      staticWidth: 200,
    },
  },
};

export default customColumnRenderers;
